.App {
  text-align: center;
	min-height: 100vh;
	display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 10vmin;
	width: auto;
  pointer-events: none;
}

.App-logo-start {
  height: 50vmin;
	width: auto;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
	position: absolute;
	top: 50%;
	right: 50%;
  width: 50px;
  height: 50px;
  border: 10px solid #CACCCE; /* Light */
  border-top: 10px solid #006699; /* dark */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.modalSignIn {
	display: flex;
	align-items: center;
	justify-content: center;
	/* width: 100%;
	height: 100%; */
}
